/**
 * Form Page Component
 *
 * Description:
 *
 *
 *
 * Components Used:
 *
 *
 * Author:
 *  - Ayaz Shah (ayaz.shah@mongodb.com)
 *  - Adlai Gordon (adlai.gordon@mongodb.com)
 *  - Federico Inserra (federico.inserra@mongodb.com)
 */

import Layout from "../../components/Layout/Layout";
import { Col, Row } from "react-bootstrap";
import { Option, Select, OptionGroup } from "@leafygreen-ui/select";
import { Combobox, ComboboxOption } from "@leafygreen-ui/combobox";
import TextInput from "@leafygreen-ui/text-input";
import TextArea from "@leafygreen-ui/text-area";
import React, { useEffect, useState, useContext } from "react";
import FormFooter from "@leafygreen-ui/form-footer";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { SearchInput, SearchResult } from "@leafygreen-ui/search-input";
import { H1, Body, Label, Link, Description } from "@leafygreen-ui/typography";
import Badge from "@leafygreen-ui/badge";
import Banner from "@leafygreen-ui/banner";
import Button from "@leafygreen-ui/button";
import addDays from "date-fns/addDays";
import { AuthContext } from "../../realmApp/AuthProvider";
import { useNavigate } from "react-router-dom";
import Toast from "@leafygreen-ui/toast";
import { app } from "../../realmApp/realmApp";
import Checkbox from "@leafygreen-ui/checkbox";
import * as Realm from "realm-web";
import { useOktaAuth } from "@okta/okta-react";
import "../forms/form.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import TextareaAutosize from 'react-textarea-autosize';
//import DayTimePicker from '@mooncake-dev/react-day-time-picker';
//import ReactDatePicker from "react-datepicker";
import DayTimePicker from '@gautierc/datepicker'
import moment from 'moment-timezone'

interface Props {
  isProtected: boolean;
}

const meetingTypeMap = { // This object map the name of the card of the frontend with the parameter accepted by the function in the backend
  "sa": "E2E",
  "discovery": "DiscoveryDemo",
  "webinar": "Webinar",
  "workshop": "Workshop",
  "preonboarding": "PreOnboarding",
  "searchtfw": "Search-TFW",
  "nwtfw": "TFW",
  "csmSupport": "CSM",
  "ramSolutions" : "RAM"
}

export const FormComponent = ({ isProtected }: Props) => {
  // Get the card paramter "cardLink" passed from home page
  const { card } = useParams<{ card: string }>();

  // Get start date to the nearest 15 minutes
  const getNearestFutureQuarterHour = (date) => {
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();
    const totalMinutes =
      hour * 60 + minutes + seconds / 60 + milliseconds / 60000;

    const roundedTotalMinutes = Math.ceil(totalMinutes / 15) * 15;

    const nearestFutureQuarterHour = new Date(date);
    nearestFutureQuarterHour.setHours(Math.floor(roundedTotalMinutes / 60));
    nearestFutureQuarterHour.setMinutes(roundedTotalMinutes % 60);
    nearestFutureQuarterHour.setSeconds(0);
    nearestFutureQuarterHour.setMilliseconds(0);

    return nearestFutureQuarterHour;
  };

  // Get end date to the nearest 15 minutes
  const getNearestFutureEndQuarterHour = (date) => {
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();
    const totalMinutes =
      hour * 60 + minutes + seconds / 60 + milliseconds / 60000;

    const roundedTotalMinutes = Math.ceil(totalMinutes / 15) * 15 + 15;

    const nearestFutureQuarterHour = new Date(date);
    nearestFutureQuarterHour.setHours(Math.floor(roundedTotalMinutes / 60));
    nearestFutureQuarterHour.setMinutes(roundedTotalMinutes % 60);
    nearestFutureQuarterHour.setSeconds(0);
    nearestFutureQuarterHour.setMilliseconds(0);

    return nearestFutureQuarterHour;
  };

  /* 
    Establish stateful values for the form -- useState(<defaultValue>)
    Determines if a field will be hidden or shown by default
  */
  const [isRSCUrgentRequestBannerHidden, setRSCUrgentRequestBannerHidden] = useState(true);
  const [productsFieldHidden, setProductsFieldHidden] = useState(true);
  const [salesSegmentFieldHidden, setSalesSegmentFieldHidden] = useState(true);
  const [runbooksHidden, setRunbooksHidden] = useState(true);
  const runbookDescriptions: { [key: string]: string } = {
    "Support Health Assessments": "Support CSMs in technical/data driven HVAs (High Value Activities)",
    "Backup optimisations runbook": "Sizing/resizing, predictive forecasting scenarios, partner w/ BVC to present unified economics approach",
    "Support Architecture Reviews": "Analyze current architecture, areas of opportunity for MDB, and challenges related to poor architecture decisions",
    "Technical Readiness Review": "Review go-live dependent product functionalities & validate testing methodologies, ensure RCs are met in setup configurations",
    "Technical Success Plan": "Validate technical goals, sizing, architecture composition, pre-onboarding checklist, timelines, and milestones for go-lives, comprehensive Project Plan, Disaster Recovery Planning",
    "Support post go-live lookback": "Data driven post-go-live debriefs (ex. Discussing Required Capabilities / Technical Metrics met in go-live event)",
    "Pre-prod Design Validation": "Validate best practice design of Schema / Data Model",
    "Support go-live": "Coach customers on their required change management initiatives, Assistance through critical go-live event"
  };

  const [expectedParticipantFieldHidden, setExpectedParticipantFieldHidden] =
    useState(true);
  const [participantPersonasFieldHidden, setParticipantPersonasFieldHidden] =
    useState(true);
  const [routeFieldHidden, setRouteFieldHidden] = useState(true);
  const [nbmDeckLinkFieldHidden, setNBMDeckLinkFieldHidden] = useState(true);
  const [tfwCheckListFieldHidden, setTFWCheckListFieldHidden] = useState(true);
  const [tfwApprovedHidden, setTFWApprovedHidden] = useState(true);
  const [nbmDeck3WhyHidden, setNBMDeck3WhyHidden] = useState(true);
  const [dateOptionalHidden, setDateOptionalHidden] = useState(true);
  const [marketingCampaignHidden, setMarketingCampaignHidden] = useState(true);
  const [workshopTypeHidden, setWorkshopTypeHidden] = useState(true);
  const [useCaseFieldHidden, setUseCaseFieldHidden] = useState(true);
  const [primarySAFieldHidden, setPrimarySAFieldHidden] = useState(true);
  const [demoFieldHidden, setDemoFieldHidden] = useState(true);
  
  
  // prep-TFW questions
  const [TFWQuestions, setTFWQuestions] = useState({
    1 : {"a": "", "q" : "How do they use MongoDB Today ?"},
    2 : {"a": "", "q" : "What are their current pains ? What are we trying to solve ?"},
    3 : {"a": "", "q" : "What are the negative consequences (business impact) of not solving these pains ?"},
    4 : {"a": "", "q" : "Any specific topic the SA should really focus on ?"},
    5 : {"a": "", "q" : "Atlas Project's link"},
    //7 : {"a": "", "q" : "If you have one, please share the Sizing Questionnaire's link. Template:https://docs.google.com/spreadsheets/d/1zChOhzpwX9G4O1wrwlhnk_0801klzXcCQbCGldDZC1g/edit?usp=sharing"}
  });

  // notice the lack of S at the end
  const setTFWQuestion = (questionId, answer) => {
    setTFWQuestions(prevQuestions => ({
      ...prevQuestions,
      [questionId]: { "q" : prevQuestions[questionId]["q"], "a" : answer }
    }));
  };

  /* Set if SFDC Account or Opportunity is Required or not
  */
  // Sets default state of required
  const [accountFieldRequired, setAccountFieldRequired] = useState(true);
  const [accountFieldLabel, setAccountFieldLabel] = useState<string>("Account Name *");
  const [opportunityFieldRequired, setOpportunityFieldRequired] = useState(true);
  const [opportunityFieldLabel, setOpportunityFieldLabel] = useState<string>("Salesforce Opportunity Name *");

  // Call this function if not required 
  const setAccountOppFieldsNotRequired = () => {
    setAccountFieldRequired(false);
    setAccountFieldLabel("Account Name");
    setOpportunityFieldRequired(false);
    setOpportunityFieldLabel("Salesforce Opportunity Name");
  };

/*
    Set the default values for each field
    Also need to create a hidden switch above
  */

  const [salesSegment, setSalesSegment] = useState<string>("");

  const [runbook, setRunbook] = useState<string>("");

  const [title, setTitle] = useState<string>("");
  const [leadTimeText, setLeadTimeText] = useState<string>("");
  const [leadTime, setLeadTime] = useState(0);
  const [startDate, setStartDate] = useState(() => {
    const currentDate = new Date(); // Get the current date
    currentDate.setDate(currentDate.getDate() + leadTime); // Add leadTime days to the current date
    return currentDate; // Return the updated start date
  });

  const [startTime, setStartTime] = useState(
    getNearestFutureQuarterHour(new Date())
  );
  const [endTime, setEndTime] = useState(
    getNearestFutureEndQuarterHour(new Date())
  );

  const [routeValue, setRouteValue] = useState("");
  const [oppsList, setOppsList] = useState([]);
  const [selectedOpp, setSelectedOpp] = useState("");
  const [disableOpp, setDisableOpp] = useState(true);
  const [accountId, setAccountId] = useState("");
  const [accountInfo, setAccountInfo] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [subjectValue, setSubjectValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [isOptionalDateChecked, setOptionalDateChecked] = useState(false);
  const [isEscalationChecked, setEscalation] = useState(false);
  const [isUSAGov, setUSAGov] = useState(false);

  const handleEscalationChange = (bool) => {
    setEscalation(bool)
    if (bool && meetingDuration == "") changeMeetingDuration("01:00")
  }


  const [selectedDuration, setSelectedDuration] = useState(null);
  const durations = [
    { label: '30min', value: "00:30" },
    { label: '1h', value: "01:00" },
    { label: '1h30', value: "01:30" },
    { label: '2h', value: "02:00" },
    { label: '2h30', value: "02:30" },
    { label: '3h', value: "03:00" },
    { label: '3h30', value: "03:30" },
    { label: '4h', value: "04:00" }
  ];

  // Auto detect timezone
  const [similarTimezone, setSimilarTimezone] = useState('');
  
  let closestTimezone = "Europe/London";
  const timezones = {
    "America/Los_Angeles" : "America - Los Angeles  | Pacific time",
    "America/Denver" :      "America - Denver     | Mountain time",
    "America/Chicago" :     "America - Chicago    | Central time",
    "America/New_York" :    "America - New York   | Eastern time",
    "Europe/London" : "Europe - London",
    "Europe/Madrid" : "Europe - Madrid, Paris, Berlin",
    "Israel" : "Israel",
    "Asia/Dubai" : "Dubai",
    "Asia/Kolkata" : "India",
    "Asia/Singapore" : "Singapore",
    "Australia/Sydney" : "Sydney"
  };

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || "failed";
  if (browserTimezone == "failed") {
    console.log("Failed to detect browser timezone");
    closestTimezone = ""
  } else {
    console.log("Browser Timezone: ", browserTimezone);

    let smallestDifference = Infinity;

    // Get the current time in the browser's timezone
    const now = moment();
    const momentTime1 = moment(now.format('HH:mm'), "HH:mm");

    Object.keys(timezones).forEach((tz) => {

      const tzTime = moment.tz(tz);
      const momentTime2 = moment(tzTime.format('HH:mm'), "HH:mm");
      const difference = Math.abs(momentTime1.diff(momentTime2, 'minutes'));

      if (difference < smallestDifference) {
        smallestDifference = difference;
        closestTimezone = tz;
      }
    });

    console.log(`Closest timezone: ${closestTimezone} with a difference of ${smallestDifference} minutes.`)
  }
  // These control the calendar 
  const [timezoneValue, setTimezoneValue] = useState(closestTimezone);
  const [meetingDuration, setMeetingDuration] = useState("")
  const [languagesValue, setLanguageValue] = useState("English");
  const [showCalendar, setShowCalendar] = useState(false)
  const [dateConfirmed, setDateConfirmed] = useState(false)
  const [dateCalendar, setDateCalendar] = useState("")
  const [bypass, setBypass] = useState(false)
  const [startTimeCalendar, setStartTimeCalendar] = useState("")
  const [endTimeCalendar, setEndTimeCalendar] = useState("")
  const [apiParameter, setApiParameters] = useState({})
  const [userRegion, setUserRegion] = useState("")
  // this variable it is useful for remounting the calendar component.
  // The value that assumes is not important, the only important thing is that everytime his value changes to true to false or viceversa, the calendar component is reset
  // we change the value of this variable everytime the user changes one of the parameters needed to choose date and time from the calendar, so that he need to select that again
  const [resetCalendar, setResetCalendar] = useState(false)

  const [resetForm, setResetForm] = useState("test")
  // End of calendar controls

  const [productsValue, setProductsValue] = useState("");
  const [expectedParticipantsValue, setExpectedParticipantsValue] =
    useState("");
  const [participantsPersonasValue, setParticipantsPersonasValue] =
    useState("");
  const [extraEmailsValue, setExtraEmailsValue] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [emailCache, setEmailCache] = useState({});
  const [rdEmailList, setRDEmailList] = useState([]);
  const [primarySAList, setPrimarySAList] = useState([]);
  const [primarySAValue, setPrimarySAValue] = useState("");
  const [workshopTypeValue, setWorkshopTypeValue] = useState("");
  const [useCaseValue, setUseCaseValue] = useState("");
  const [demosValue, setDemosValue] = useState("");
  const [tfwChecklistValue, setTFWChecklistValue] = useState("https://deprecated.rsc");
  const [nbmDeckLinkValue, setNBMDeckLinkValue] = useState("");
  const [tfwApprovedValue, setTFWApprovedValue] = useState("");
  const [marketingCampaignValue, setMarketingCampaignValue] = useState("");
  const [nbmDeckValue, setNBMDeckValue] = useState("");
  const [escalationReasonsValue, setEscalationReason] = useState("");
  const [userProfile, setUserProfile] = useState<{
    data: {
      firstName: string;
      lastName: string;
      email: string;
      name: string;
    };
    identities: any[];
    type: string;
  } | null>(null);
  const [open, setOpen] = useState(false);
  const [validationToastOpen, setValidationToastOpen] = useState(false);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [warningToastOpen, setWarningToastOpen] = useState(false);
  const [progressToastOpen, setProgressToastOpen] = useState(false);
  const [ticketId, setTicketID] = useState("");
  const [ticketWarning, setTicketWarning] = useState("Error creating your ticket, please contact us on slack at #censos-intake-form-problem");


  // Validation Fields
  const [ticketValidation, setTicketValidation] = useState([]);
  const [isAccountSelected, setIsAccountSelected] = useState(false);
  const [isCcSelected, setIsCcSelected] = useState(false);
  const [isRdSelected, setIsRdSelected] = useState(false);
  const [isprimarySASelected, setIsprimarySASelected] = useState(false);
  const navigate = useNavigate();

  // Get the user from the context
  const [user, setUser] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();

  // This is the user used to invoke the functions
  const [appUser, setAppUser] = useState(null);

  // Store RSC team availabilities
  const [teamAvailabilities, setTeamAvailabilities] = useState([])

  useEffect(() => {
    const currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() + leadTime); // Add leadTime days to the current date
    if (currentDate.getDay() === 0) { // if date falls on Sunday, add 1 day
      currentDate.setDate(currentDate.getDate() + 1); 
    } else if (currentDate.getDay() === 6) { // if date falls on Saturday, add 2 day
      currentDate.setDate(currentDate.getDate() + 2);
    }
    setStartDate(currentDate); // Update the startDate state with the new calculated date
  }, [leadTime]);

  /*
  useEffect( () => { 
    // This activates everytime one of the value listed change
    // It is for calling the api to sync the calendar availabilities for the team
    if (showCalendar) syncCalendar()
  }, [showCalendar, meetingDuration, languagesValue, timezoneValue] )
  */

  /* Adding a new Form
      This section set the values of fields based on the cardLink parameter -- change the default value, or show/hide
      To add a new form, add a new if block

      else if (card === "<cardLink>") {
        setTitle("<Top Banner Text>");
        setLeadTimeText("XX Days"); // String to show
        setLeadTime(6); // Integer to block of this many upcoming calendar days
      }

      All form fields are hidden by default. 
      To unhide: set<FormFieldName>Hidden(false);
      
      Example:
      setProductsFieldHidden(false);
      setExpectedParticipantFieldHidden(false);
      setMarketingCampaignHidden(false);
    
  */

  useEffect(() => {
    if (card === "discovery") {
      setTitle("Discovery call");
      setLeadTimeText("2 Days");
      setLeadTime(2);
      setRSCUrgentRequestBannerHidden(false);
      setProductsFieldHidden(false);
      setExpectedParticipantFieldHidden(false);
      setParticipantPersonasFieldHidden(false);
      setUseCaseFieldHidden(false);
      setDemoFieldHidden(false);
    } else if (card === "webinar") {
      setTitle("Webinar and Product Demo");
      setLeadTimeText("6 Days");
      setLeadTime(6);
      setRSCUrgentRequestBannerHidden(false);
      setProductsFieldHidden(false);
      setExpectedParticipantFieldHidden(false);
      setMarketingCampaignHidden(false);
      setAccountOppFieldsNotRequired();
    } else if (card === "workshop") {
      setTitle("Hands-on Lab");
      setLeadTimeText("6 Days");
      setLeadTime(6);
      setRSCUrgentRequestBannerHidden(false);
      setExpectedParticipantFieldHidden(false);
      setMarketingCampaignHidden(false);
      setWorkshopTypeHidden(false);
      setAccountOppFieldsNotRequired();
    } else if (card === "sa") {
      setTitle("End-to-End Opportunity");
      setLeadTimeText("2 Days");
      setLeadTime(2);
      setRSCUrgentRequestBannerHidden(false);
      setDateOptionalHidden(false);
      setNBMDeck3WhyHidden(false);
    } else if (card === "preonboarding") {
      setTitle("Pre-Onboarding");
      setLeadTimeText("1 Day");
      setLeadTime(1);
      setRSCUrgentRequestBannerHidden(false);
      setSalesSegmentFieldHidden(false);
    } else if (card === "searchtfw") {
      setTitle("Search TFW");
      setLeadTimeText("2 Days");
      setLeadTime(2);
      setRSCUrgentRequestBannerHidden(false);
      setExpectedParticipantFieldHidden(false);
      setParticipantPersonasFieldHidden(false);
      setTFWCheckListFieldHidden(false);
    } else if (card === "nwtfw") {
      setTitle("New Workload TFW");
      // setLeadTimeText("3 Days");
      setLeadTime(5);
      setRSCUrgentRequestBannerHidden(false);
      setRouteFieldHidden(false);
      setNBMDeckLinkFieldHidden(false);
      setTFWCheckListFieldHidden(false);
      setTFWApprovedHidden(false);
    } else if (card === "csmSupport") {
      setTitle("RAM & CSM Support");
      setLeadTimeText("3 Days");
      setLeadTime(3);
      setRSCUrgentRequestBannerHidden(false);
      setSalesSegmentFieldHidden(false);
    } else if (card === "CSPilot") {
      setTitle("CS Pilot Group");
      setLeadTimeText("3 Days");
      setLeadTime(3);
      setRSCUrgentRequestBannerHidden(false);
      setSalesSegmentFieldHidden(true);
      setRunbooksHidden(false);
    }else if (card === "ramSolutions") {
      setTitle("RAM Solutions Workshop");
      setLeadTimeText("5 Days");
      setLeadTime(5);
      setRSCUrgentRequestBannerHidden(false);
      setSalesSegmentFieldHidden(true);
    }else if (card.includes("specialist")) {
      setTitle("Specialist Team");
      setDateOptionalHidden(false);
      setPrimarySAFieldHidden(false);
      
      if (card.includes("speaker")) {
        setAccountOppFieldsNotRequired();
        setTitle("Specialist Speaker Request");
      }
        
    }
    else {
      navigate(`*`)
    }
  }, []);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUser(null);
    } else {
      oktaAuth.getUser().then((info) => {
        //setUser(info);
        loginApiKey(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const loginApiKey = (info) => {
    
    // Getting the JWT token from Okta authState and passing as an paran to Realm
    const credentials = Realm.Credentials.jwt(authState.idToken?.idToken);

    app.logIn(credentials).then((appUser) => {
      setAppUser(appUser);
      appUser.functions.get_user_role_team({user:info.email})
        .then((userInfo) => {
          
          info.title = userInfo.result.title
          info.department = userInfo.result.department

          setUser(info)
        })
      
    });
  };

  

  const getFormattedMinutes = (date) => {
    const minutes = date.getMinutes();
    return minutes < 10 ? `0${minutes}` : `${minutes}`;
  };

  const formatHour = (hour) => {
    return String(hour).padStart(2, "0");
  };


/* Validation
  Determine if a field has a valid value.
  Fail validation if not

  Build up an array of Validation Errors to display to the user upon validation failure

*/
  const addValidationError = (errorText) => {
    setTicketValidation((prevState) => [...prevState, errorText]);
  };

  const validation = (): boolean => {
    setTicketValidation([]);
    let validationPassed = true;
    if (accountFieldRequired && accountInfo === "") {
      validationPassed = false;
      addValidationError("Account Name is a required field");
    }

    if (opportunityFieldRequired && selectedOpp === "") {
      validationPassed = false;
      addValidationError("Salesforce Opportunity Name is a required field");
    }

    if (card === "preonboarding" || card === "csmSupport") {
      if (salesSegment === "") {
        addValidationError("Your Sales Segment is a required field");
        validationPassed = false;
      }
    }

    if (subjectValue === "") {
      addValidationError("Subject is a required field");
      validationPassed = false;
    }

    if (descriptionValue === "") {
      addValidationError("Description is a required field");
      validationPassed = false;
    }


    //if (startDate === null && isOptionalDateChecked === false) {
     // addValidationError("Meeting Date is a required field");
      //validationPassed = false;
    //}

    if (card === "workshop") {
      if (workshopTypeValue === "") {
        validationPassed = false;
        addValidationError("Hands-on Lab Type is a required field");
      }
    }

    if (timezoneValue === "") {
      validationPassed = false;
      addValidationError("Timezone is a required field");
    }

    if (
      card === "discovery" ||
      card === "webinar" ||
      card === "workshop" ||
      card === "searchtfw"
    ) {
      if (expectedParticipantsValue === "") {
        validationPassed = false;
        addValidationError("Expected Participant Size is a required field");
      }
    }

    if (card === "webinar") {
      if (productsValue === "") {
        validationPassed = false;
        addValidationError("Product is a required field");
      }
    }

    if (card === "searchtfw") {
      if (participantsPersonasValue === "") {
        validationPassed = false;
        addValidationError("Participant Personas is a required field");
      }

      if (tfwChecklistValue === "") {
        validationPassed = false;
        addValidationError("Copy of the TFW Checklist is a required field");
      }
    }

    if (card === "discovery") {
      if (productsValue === "") {
        validationPassed = false;
        addValidationError("Product is a required field");
      }

      if (participantsPersonasValue === "") {
        validationPassed = false;
        addValidationError("Participant Personas is a required field");
      }
    }

    if (card === "nwtfw") {
      if (routeValue === "") {
        validationPassed = false;
        addValidationError("Route is a required field");
      }
      if (nbmDeckLinkValue === "") {
        validationPassed = false;
        addValidationError("NBM Deck Link is a required field");
      }

      if (tfwChecklistValue === "") {
        validationPassed = false;
        addValidationError("Copy of the TFW Checklist is a required field");
      }

      /* if (tfwApprovedValue === "") {
        validationPassed = false;
        addValidationError("TFW Approved by RD is a required field");
      } */
    }

    if (languagesValue == "") {
      validationPassed = false;
      addValidationError("Language is a required field");
    }

    if (card.includes("specialist")) {
      if (primarySAValue === "") {
        validationPassed = false;
        addValidationError("Primary SA is a required field");
      }

    }

    if (isOptionalDateChecked === false && (dateCalendar === "" || dateCalendar === undefined)) {
      validationPassed = false
      addValidationError("Date of the meeting is a required Field")
    }

    return validationPassed;
  };


  const clear_form = async () => {

    setOppsList([])
    setSelectedOpp("")
    setDisableOpp(true)
    setAccountId("")
    setAccountInfo("")
    setAccountList([])
    setSubjectValue("")
    setDescriptionValue("")
    setProductsValue("")
    setExpectedParticipantsValue("")

    setTimezoneValue("")
    setMeetingDuration("")
    setLanguageValue("")
    setShowCalendar(false)
    setDateConfirmed(false)
    setDateCalendar("")
    setStartTimeCalendar("")
    setEndTimeCalendar("")
    setUserRegion("")
    setResetCalendar(!resetCalendar)

    
    setExtraEmailsValue([])
    setEmailList([])
    setEmailCache({})
    setPrimarySAList([])
    setPrimarySAValue("")


    setParticipantsPersonasValue("")
    setRDEmailList([])
    setWorkshopTypeValue("")
    setUseCaseValue("")
    setDemosValue("")
    setTFWChecklistValue("")
    setNBMDeckLinkValue("")
    setTFWApprovedValue("")
    setMarketingCampaignValue("")
    setNBMDeckValue("")

    setEscalation(false)
    setEscalationReason("")

    setUSAGov(false)

    setResetForm(resetForm+"a")
    
    }

  /* 
    Build up body of API call to Atlas App Services, which then in turn calls Zendesk

  */

  const create_ticket = async () => {
    
    if (user === undefined) return;


    if (validation() === false) {
      setValidationToastOpen(true);
      return;
    } else {
      setValidationToastOpen(false);
      setWarningToastOpen(false);
      setSuccessToastOpen(false);
      setProgressToastOpen(true);

      //Fix bad timezone
      let ticket_tz = (timezoneValue === "Europe/Madrid") ? "Europe/Central" : timezoneValue;

      //retrieve the emails for CC field
      let CCfield = [];
      extraEmailsValue.forEach((e) => {
        CCfield.push({ user_email: emailCache[e] });
      });

      let ticket = {
        subject: subjectValue,
        description: descriptionValue,
        brand: "Sales",
        formType: "Remote Solution Center",
        requester: {
          name: `${user.name}`,
          email: `${user.email}`,
        },
        "Your Sales Segment": salesSegment,
        group: "SO-L1-Solution-Architects",
        CC: CCfield,
        "Meeting time (regex)": startTimeCalendar,
        "Meeting end time (regex)": endTimeCalendar,
        "Meeting Date": dateCalendar,
        "Timezone (SA)": ticket_tz,
        "Number of Attendees (Expected)": Number(expectedParticipantsValue),
        "Expected Participant Size": Number(expectedParticipantsValue),
        "Number of Attendees (Actual)": 0,
        "Customer name": accountInfo.replaceAll("^",""),
        Products: productsValue,
        "Participant Personas": participantsPersonasValue,
        "Language Requirements (SA)": languagesValue,
        "Use Case": useCaseValue,
        Demos: demosValue,
        "Copy of the TFW Checklist (SA)": tfwChecklistValue,
        "NBM Deck link (SA)": nbmDeckLinkValue,
        "TFW approved by RD (SA)": tfwApprovedValue,
        "SFDC Campaign Link": marketingCampaignValue,
        'NBM / "3 Whys" deck': nbmDeckValue,
        "calendarInfo": {
          "timezone": ticket_tz,
          "region": userRegion,
          "duration": meetingDuration,
          "language": languagesValue,
          "ticket_date": dateCalendar + "T" + startTimeCalendar,
          "meeting_type": meetingTypeMap[card],
          "meeting_subtype": productsValue + workshopTypeValue.split("|")[0],
          "escalated": isEscalationChecked,
          "usa_gov"   : isUSAGov
        }
      };

      if (!tfwCheckListFieldHidden) { 
        ticket["description"] += "\n\nNBM: " + nbmDeckLinkValue
      
        ticket["description"] += "\n\nTFW Checklist:\n"
        let atLeastOne = false;
        Object.values(TFWQuestions).forEach(question => {
          if (question.a !== "") {
            atLeastOne = true;
            ticket["description"] += "~ " + question.q + "\n" + question.a + "\n\n" 
          }
        });
        if (!atLeastOne) {ticket["description"] += "~ none answered"}
      }

      if (isUSAGov) { 
        ticket["description"] += "\n\n🛑 USA Gov meeting"
      }


      if (isEscalationChecked) { 
        ticket["calendarInfo"]["escalated_reason"] = escalationReasonsValue
        ticket["description"] += "\n\nEscalation Reason:\n"+escalationReasonsValue
      }


      if (selectedOpp === "") {
        ticket["SFDC Opportunity link (or Account link)"] = {
          type: "account",
          id: accountId,
        };
      } else {
        // Retrieve the opp id from the list of opportunities
        let opp = oppsList.find((op) => op.nm === selectedOpp);
        if (Array.isArray(opp)) opp = opp[0];
        ticket["SFDC Opportunity link (or Account link)"] = {
          type: "opportunity",
          id: opp._id,
        };
      }

      if (card === "sa") {
        ticket["Type Of Request (SA)"] = "End-to-End opportunity SA";
        if (isOptionalDateChecked === true) {
          ticket["Meeting Date"] = null;
          ticket["Meeting time (regex)"] = null;
          ticket["Meeting end time (regex)"] = null;
        }
      } else if (card === "discovery") {
        ticket["Type Of Request (SA)"] = "Customer call";
        ticket["Sales Call (sub)"] = "Discovery/Demo";
      } else if (card === "webinar") {
        ticket["Type Of Request (SA)"] = "Webinar";
      } else if (card === "workshop") {
        ticket["Type Of Request (SA)"] = "eWorkshop";
        ticket["eWorkshop"] = workshopTypeValue.split("|")[0];
        ticket["description"] += `\nHands-on Lab: ${workshopTypeValue.split("|")[1] || "Other"}`
      } else if (card === "preonboarding") {
        ticket["Type Of Request (SA)"] = "Customer call";
        ticket["Sales Call (sub)"] = "Pre-Onboarding";
      } else if (card === "searchtfw") {
        ticket["Type Of Request (SA)"] = "Customer call";
        ticket["Sales Call (sub)"] = "Search TFW";
      } else if (card === "nwtfw") {
        ticket["Type Of Request (SA)"] = "Customer call";
        ticket["Sales Call (sub)"] = routeValue;
      } else if (card === "csmSupport") {
        ticket["Type Of Request (SA)"] = "Customer call";
        ticket["Sales Call (sub)"] = "Discovery/Demo";
      } else if (card === "ramSolutions") {
        ticket["Type Of Request (SA)"] = "Customer call";
        ticket["Sales Call (sub)"] = "RAM Solutions Workshop";
      }

      // Call the Realm Function "create_zendesk_ticket"
      
      const result = await appUser.functions.create_zendesk_ticket(ticket);

      /*  Testing Only -- so that we view & check the newly created ticket
          Future: Redirect to https://help-sales.mongodb.com/hc/en-us/requests/{TICKETID}

      */
      if (result.status === 200) {
        setProgressToastOpen(false);
        setSuccessToastOpen(true); // set state to true on success
        setTicketID(
          `https://help-sales.mongodb.com/hc/en-us/requests/${result.ticketId}`
        );
        window.open(`https://help-sales.mongodb.com/hc/en-us/requests/${result.ticketId}`, '_blank');
      } else if (result.status === 409) {
        setProgressToastOpen(false);
        setWarningToastOpen(true);
        setTicketWarning(`This particular time and date is no longer available - please select a different slot`);
      
      } else if (result.status === 400) {
        setProgressToastOpen(false);
        setWarningToastOpen(true);

        let errorBody = result.zendeskResponse.body
        setTicketWarning(`${errorBody} \n\nIf the issue persists, please contact us on slack at #censos-intake-form-problem`);
      
      } else {
        setProgressToastOpen(false);
        console.log(result)
        setWarningToastOpen(true);
      }
    }
  };

 

  const searchAccount = async (event: any) => {
    if (isAccountSelected) {
      // Reset the flag once we've ignored one change.
      setIsAccountSelected(false);
      return;
    }

    const searchQuery = event.target.value;

    if (user === undefined) return;

    setAccountInfo(searchQuery);

    if (searchQuery.length < 3) {
      setAccountList([]);
      return;
    }

    let search = { searchTerm: searchQuery };

    const response = await appUser.functions.search_account(search);

    setAccountList(response.result);

    //clean the opp field
    setSelectedOpp("")
  };

  const selectAccount = async (acct: any) => {
    setIsAccountSelected(true);

    let search = { account_id: acct._id };

    const response = await appUser.functions.search_opp(search);

    setOppsList(response.result);
    setDisableOpp(false);

    setAccountInfo(acct.nm);
    setAccountId(acct._id);
  };

  const searchRDEmail = async (searchQuery: any) => {
    if (user === undefined) return;

    setTFWApprovedValue(searchQuery);

    if (searchQuery.length < 3) {
      setRDEmailList([]);
      return;
    }

    let search = { searchTerm: searchQuery };

    const response = await appUser.functions.search_by_email(search);

    setRDEmailList(response.result);
  };

  const searchEmail = async (searchQuery: any) => {

    if (user === undefined) return;

    if (searchQuery.length < 3) {
      setEmailList([]);
      return;
    }

    let search = { searchTerm: searchQuery };

    const response = await appUser.functions.search_by_email(search);

    let emails = [];

    response.result.forEach((email) => {
      const concatName = `${email.firstName} ${email.lastName}`;
      emails.push(concatName);
      let cacheObject = emailCache;
      cacheObject[concatName] = email.username;
      setEmailCache(cacheObject);
    });

    setEmailList(emails);
  };

  
  const handleExtraEmailField = (e) => {
    setExtraEmailsValue(e);
    setEmailList(e);
  };



  const searchPrimarySA = async (searchQuery: any) => {
    if (user === undefined) return;

    if (searchQuery.length < 3) {
      setPrimarySAList([]);
      return;
    }

    let search = { searchTerm: searchQuery };

    const response = await appUser.functions.search_by_email(search);

    let emails = [];

    response.result.forEach((email) => {
      
      emails.push(email.username);
      
    });

    setPrimarySAList(emails);
  };


  const isWeekday = (date: Date): boolean => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const filterEndTime = (time: Date): boolean => {
    // const currentDate = new Date();
    const selectedDate = new Date(time);

    return startTime.getTime() < selectedDate.getTime();
  };

  const selectRoute = (route) => {
    setRouteValue(route);
    if (route === "New Workload TFW - Fast") {
      setLeadTimeText("3 Days");
      setLeadTime(3);
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate());
      setStartDate(currentDate);
    } else {
      setLeadTimeText("5 Days");
      setLeadTime(5);
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate());
      setStartDate(currentDate);
    }
  };


  

  // CALENDAR FUNCTIONS 

  const changeTimezone = (selectedTimezone) => {

    setTimezoneValue(selectedTimezone)
    setDateCalendar("")

    if (languagesValue !== "" && selectedTimezone !== "" && meetingDuration !== "") {
      syncCalendar(languagesValue, selectedTimezone, meetingDuration)
      setShowCalendar(true)
      setResetCalendar(!resetCalendar)
      setDateConfirmed(false)
    }
    else {
      setShowCalendar(false)
    }

  }

  const changeLanguage = async (selectedLanguage) => {

    setLanguageValue(selectedLanguage)
    setDateCalendar("")

    if (selectedLanguage !== "" && timezoneValue !== "" && meetingDuration !== "") {
      syncCalendar(selectedLanguage, timezoneValue, meetingDuration)
      setShowCalendar(true)
      setResetCalendar(!resetCalendar)
      setDateConfirmed(false)
    }
    else {
      setShowCalendar(false)
    }
   
  }

  const changeMeetingDuration = (selectedDuration) => {
    
    setMeetingDuration(selectedDuration)
    setDateCalendar("")

    if (languagesValue !== "" && timezoneValue !== "" && selectedDuration !== "") {
      syncCalendar(languagesValue, timezoneValue, selectedDuration)
      setShowCalendar(true)
      setResetCalendar(!resetCalendar)
      setDateConfirmed(false)
    }
    else {
      setShowCalendar(false)
    }
  }

  

  const syncCalendar = async (lang, tz, durat) => {

    const api_url = "https://zendesk-app-dot-zendesk-scheduler-364414.ue.r.appspot.com/availabilities"
    
    let region = ""

    if (timezoneValue.includes("Europe")) region = "EMEA"
    else if (timezoneValue.includes("Asia")) region = "APAC"
    else if (timezoneValue.includes("Australia")) region = "APAC"
    else if (timezoneValue.includes("America")) region = "Americas"

    setUserRegion(region)
    // UPDATING

    const body = {
      "type"      : meetingTypeMap[card], 
      "sub-type"  : productsValue+workshopTypeValue,
      "timezone"  : tz, 
      "region"    : region,
      "duration"  : durat,
      "language"  : lang,
      "usa_gov"   : isUSAGov
    }

    setApiParameters(body)

  }

  const convertDateToString = datetime => {


    if (typeof(datetime) === "number") {
      datetime = new Date(datetime)
    }

    let month = datetime.getMonth() + 1
    if (month < 10) month = "0"+month

    let day = datetime.getDate()
    if (day < 10) day = "0"+day

    let hours = datetime.getHours()
    if (hours < 10) hours = "0"+hours

    let minutes = datetime.getMinutes()
    if (minutes < 10) minutes = "0"+minutes 

    let calendarTime = datetime.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":" + "00"

    return calendarTime

  }

  const confirmDateCalendar = (datetime) => {
    setDateConfirmed(true)
    handleDateTime(datetime)
  };

  const handleDateTime = datetime => {

    setDateCalendar(datetime)
    let stringDate = convertDateToString(datetime)

    let splitDate = stringDate.split("T")
    setDateCalendar(splitDate[0])
    setStartTimeCalendar(splitDate[1].slice(0,5))

    let time = splitDate[1].split(":")
    let durationSplit = meetingDuration.split(":")

    let hours = parseInt(time[0]) + parseInt(durationSplit[0])
    let minutes;

    if (parseInt(time[1]) === 30 && parseInt(durationSplit[1]) === 30 ) {
      minutes = "00"
      hours += 1
    }
    else {
      minutes = parseInt(time[1]) + parseInt(durationSplit[1])
      if (minutes === 0) minutes = "00"
    }
    
    let hoursString
    if (hours < 10) hoursString = "0"+hours
    else hoursString = ""+hours

    let endTime = hoursString + ":" + minutes

    setEndTimeCalendar(endTime)
  }
  

  return (
    
    <Layout key={resetForm}>
      <div>
        <Row className="form-hero-image">
          <Col>
            <H1 className="form-hero-text">{title}</H1>
          </Col>
        </Row>
        <Row className="navigationRow">
          <Col></Col>
          <Col xs={12} md={10} lg={10}>
            <Body className="navigationStyle">
              <a className="navigationRefStyle" href="/">
                Home
              </a>
              <span> &#62; </span> {title}
            </Body>
          </Col>
          <Col></Col>
        </Row>
        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <div>
              {routeFieldHidden ? null : (
                <Select
                  className="fieldMargin"
                  label="Route *"
                  name="route"
                  baseFontSize={13}
                  onChange={(selectedValue) => selectRoute(selectedValue)}
                >
                  <Option value="New Workload TFW - Fast">Fast</Option>
                  <Option value="New Workload TFW - Classic">Classic</Option>
                </Select>
              )}
              <Label className="fieldMargin" htmlFor="accountName">
                {accountFieldLabel}
              </Label>
              <div className="container">
              <SearchInput
                id="accountName"
                className="fieldMargin searchInput"
                value={accountInfo}
                onChange={(event) => searchAccount(event)}
                aria-label="Account Name"
              >
                {accountList.map((acct: any, index) => {
                  return (
                    <SearchResult
                      onClick={() => selectAccount(acct)}
                      key={index}
                    >
                      {acct.nm} - {acct.owner}
                    </SearchResult>
                  );
                })}
              </SearchInput>
              <Checkbox
                      className="my-checkbox"
                      onChange={() => {
                        setUSAGov(!isUSAGov)
                      }}
                      label="USA Gov"
                      checked={isUSAGov}
                      bold={false}
              />
              </div>

              <Combobox
                id="searchOpp"
                className="fieldMargin"
                disabled={disableOpp}
                label={opportunityFieldLabel}
                onChange={(value) => setSelectedOpp(value)}
                value={selectedOpp} // set the current value
              >
                {oppsList.map((opp: any, index) => {
                  return <ComboboxOption key={index} value={opp.nm} />;
                })}
              </Combobox>
              <Banner className="bannerStyle fieldMargin">
              Opportunity list updated every 24 hours at 6:00 Eastern Time.<br></br>
              Please check back later if a newly created opportunity isn't shown.
              </Banner>
              {salesSegmentFieldHidden ? null : (
                <Select
                  className="fieldMargin"
                  label="Your Sales Segment *"
                  name="salesSegment"
                  baseFontSize={13}
                  onChange={(selectedValue) => setSalesSegment(selectedValue)}
                >
                  <Option value="PLS">PLS</Option>
                  <Option value="PLS">CSM</Option>
                  <Option value="PLS">RAM</Option>
                  <Option value="Acquisition">Acquisition</Option>
                  <Option value="Growth">Growth</Option>
                  <Option value="Partners">Partners</Option>
                  <Option value="Pods/Strat/FSI">Pods/Strat/FSI</Option>
                </Select>
              )}
              <TextInput
                className="fieldMargin"
                baseFontSize={13}
                label="Subject *"
                value={subjectValue}
                onChange={(event) => setSubjectValue(event.target.value)}
                optional={false}
              />
              <Label className="fieldMargin" id="description-label" htmlFor='descriptionTextArea'>
                Description *
              </Label>
              <Description>
              Detailed description of opportunity and engagement (sales challenges, customer challenges, unique considerations). Please add attachments as applicable (web links to 3 Whys, discovery capture sheet, Atlas sizing & pricing info, etc).
              </Description>
              <TextareaAutosize
                id='descriptionTextArea'
                className="fieldMargin"
                value={descriptionValue}
                minRows={12}
                onChange={(event) => setDescriptionValue(event.target.value)}
              />
              
{/*                 
                <Col>
                  
                  {isOptionalDateChecked ? null : (
                    <>
                      <Label className="fieldMargin" htmlFor="datePicker">
                        Meeting Date
                      </Label>
                      <DatePicker
                        minDate={addDays(new Date(), leadTime)}
                        className="datePicker"
                        id="datePicker"
                        selected={startDate}
                        onChange={(date: any) => setStartDate(date)}
                        dateFormat="MMMM d, yyyy"
                        filterDate={isWeekday}
                      />
                    </>
                  )}
                </Col>
                
                <Col>
                  {isOptionalDateChecked ? null : (
                    <>
                      <Label className="fieldMargin" htmlFor="datePicker">
                        Start Time
                      </Label>
                      <DatePicker
                        className="datePicker"
                        selected={startTime}
                        onChange={(date: Date) => setStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Start Time"
                        dateFormat="h:mm aa"
                      />
                    </>
                  )}
                </Col>
                <Col>
                  {isOptionalDateChecked ? null : (
                    <>
                      <Label className="fieldMargin" htmlFor="datePicker">
                        End Time
                      </Label>
                      <DatePicker
                        className="datePicker"
                        selected={endTime}
                        onChange={(date: Date) => setEndTime(date)}
                        filterTime={filterEndTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="End Time"
                        dateFormat="h:mm aa"
                      />
                    </>
                  )}
                </Col> */}
             
              
              
{/* Banner for urgent requests  was here */}
              {workshopTypeHidden ? null : (
                <Select
                  className="fieldMargin"
                  label="Hands-on Lab Type *"
                  name="workshopType"
                  baseFontSize={13}
                  onChange={(selectedValue) =>
                    setWorkshopTypeValue(selectedValue)
                  }
                >
                  <Option value="Atlas Intro -101|Atlas 101 - MongoDB Setup Lab: Building Clusters, Options, and Backups">Atlas 101 - MongoDB Setup Lab: Building Clusters, Options, and Backups</Option>
                  <Option value="Atlas Aggregation Pipeline - 220|Atlas Aggregation framework: Building Queries">Atlas Aggregation framework: Building Queries</Option>
                  <Option value="Atlas Search - 225|Atlas Search Query and Index creation">Atlas Search Query and Index creation</Option>
                  <Option value="Other|MongoDB Relational Migrator">MongoDB Relational Migrator</Option>
                  <Option value="Other|Atlas Vector Search Hands-on Lab">Atlas Vector Search Hands-on Lab</Option>
                  <Option value="Other|Other">Other</Option>
                </Select>
              )}
              {/* {workshopTypeHidden ? null : (
                  <WebinarModal />
                )} */}
              
              {productsFieldHidden ? null : (
                <Combobox
                  className="fieldMargin"
                  label="Products *"
                  multiselect={true}
                  onChange={(products: any) => setProductsValue(products)}
                >
                  <ComboboxOption value="MongoDB" />
                  <ComboboxOption value="MongoDB Enterprise Advance" />
                  <ComboboxOption value="MongoDB Atlas" />
                  <ComboboxOption value="Realm" />
                  <ComboboxOption value="Atlas Search" />
                  <ComboboxOption value="Data Lake" />
                  <ComboboxOption value="Online Archive" />
                  <ComboboxOption value="Charts" />
                  <ComboboxOption value="Database Connectors" />
                  <ComboboxOption value="Other" />
                </Combobox>
              )}
              {expectedParticipantFieldHidden ? null : (
                <TextInput
                  className="fieldMargin"
                  baseFontSize={13}
                  label="Expected Participant Size *"
                  type="tel"
                  value={expectedParticipantsValue}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value === "" || /^[0-9]+$/.test(value)) {
                      setExpectedParticipantsValue(value);
                    }
                  }}
                />
              )}
              {marketingCampaignHidden ? null : (
                <TextInput
                  className="fieldMargin"
                  baseFontSize={13}
                  label="Marketing Campaign Link"
                  optional={true}
                  value={marketingCampaignValue}
                  onChange={(event) =>
                    setMarketingCampaignValue(event.target.value)
                  }
                />
              )}
              {participantPersonasFieldHidden ? null : (
                <Combobox
                  className="fieldMargin"
                  label="Participant Personas *"
                  multiselect={true}
                  onChange={(participantsPersonas: any) =>
                    setParticipantsPersonasValue(participantsPersonas)
                  }
                >
                  <ComboboxOption value="App Owners" />
                  <ComboboxOption value="Data Analytics" />
                  <ComboboxOption value="Database Administrators" />
                  <ComboboxOption value="Developers" />
                  <ComboboxOption value="DevOps" />
                  <ComboboxOption value="Enterprise Architect" />
                  <ComboboxOption value="Executives" />
                  <ComboboxOption value="MDB Partner" />
                  <ComboboxOption value="Security" />
                </Combobox>
              )}
              {nbmDeckLinkFieldHidden ? null : (
                <TextInput
                  className="fieldMargin"
                  baseFontSize={13}
                  label="NBM Deck Link *"
                  description="Please Share with 10Gen so the SA can access"
                  optional={false}
                  value={nbmDeckLinkValue}
                  onChange={(event) => setNBMDeckLinkValue(event.target.value)}
                />
              )}
              {tfwCheckListFieldHidden ? null : (

                <Banner className="bannerStyle fieldMargin">
                As an experiement, we are deprectating the pre-TFW Checklist. Instead, please fill-out the following questions<br /><br />
                {Object.entries(TFWQuestions).map(([key, question]) => (
                  question.q === "Atlas Project's link" ? (
                    <TextInput
                      key={key}
                      className="fieldMargin"
                      baseFontSize={13}
                      label=' '
                      description={question.q}
                      value={question.a}
                      onChange={(event) => setTFWQuestion(Number(key), event.target.value)}
                      />
                  ) : (
                    <TextArea
                      key={key}
                      className="fieldMargin"
                      baseFontSize={13}
                      label=' '
                      description={question.q}
                      value={question.a}
                      onChange={(event) => setTFWQuestion(Number(key), event.target.value)}
                    />
                  )
                ))}
                </Banner>

              )}
              {nbmDeck3WhyHidden ? null : (
                <TextInput
                  className="fieldMargin"
                  baseFontSize={13}
                  label='NBM / "3 Whys" deck'
                  description="Provide Google Presentation Link"
                  optional={true}
                  value={nbmDeckValue}
                  onChange={(event) => setNBMDeckValue(event.target.value)}
                />
              )}
              {useCaseFieldHidden ? null : (
                <Select
                  className="fieldMargin"
                  label="Use Case"
                  name="useCase"
                  baseFontSize={13}
                  onChange={(selectedValue) => setUseCaseValue(selectedValue)}
                >
                  <Option value="Single View">Single View</Option>
                  <Option value="Mainframe Offload">Mainframe Offload</Option>
                  <Option value="Event-Driven Architecture">
                    Event-Driven Architecture
                  </Option>
                  <Option value="Time Series">Time Series</Option>
                  <Option value="Microservices">Microservices</Option>
                  <Option value="Mobile & Web Development">Mobile & Web Development</Option>
                  <Option value="Internet of Things">Internet of Things</Option>
                  <Option value="TBD">TBD</Option>
                  <Option value="Other">Other</Option>
                </Select>
              )}
              {demoFieldHidden ? null : (
                <Combobox
                  className="fieldMargin"
                  label="Demos"
                  multiselect={true}
                  onChange={(demos: any) => setDemosValue(demos)}
                >
                  <ComboboxOption value="MongoDB" />
                  <ComboboxOption value="MongoDB Enterprise Advance" />
                  <ComboboxOption value="MongoDB Atlas" />
                  <ComboboxOption value="Realm" />
                  <ComboboxOption value="Atlas Search" />
                  <ComboboxOption value="Data Lake" />
                  <ComboboxOption value="Online Archive" />
                  <ComboboxOption value="Charts" />
                  <ComboboxOption value="Database Connectors" />
                  <ComboboxOption value="Other" />
                </Combobox>
              )}
              {tfwApprovedHidden ? null : (
                <Combobox
                  label="TFW Approved by RD"
                  description="Name of the RD who assisted the NBM in the past"
                  placeholder="Search"
                  value={tfwApprovedValue}
                  onFilter={(event) => searchRDEmail(event)}
                >
                  {rdEmailList.map((email: any, index) => {
                    return (
                      <ComboboxOption
                        key={index}
                        value={`${email.firstName} ${email.lastName}`}
                      />
                    );
                  })}
                </Combobox>
              )}
              {primarySAFieldHidden ? null : ( 

                  <Combobox
                    label="Primary SA"
                    placeholder="Search SA"
                    onFilter={(event) => searchPrimarySA(event)}
                    onChange={(e) => setPrimarySAValue(e)}
                    multiselect={false}
                    >
                    {primarySAList.map((email: any, index) => {
                      return <ComboboxOption key={index} value={email} />;
                    })}
                  </Combobox>
                )}

              {runbooksHidden ? null : (
                <>
                <br/>
                <Label className="fieldMargin" id="description-label" htmlFor='descriptionTextArea'>
                Runbook *
                </Label>
                <Description>
                Which engagement would you like to position for this customer? 
                Find the detailed explanations on <a href="https://wiki.corp.mongodb.com/display/10GEN/CS+x+RSC%3A+Runbook+Master+list" target="_blank" rel="noopener noreferrer">
                  this page
                </a>.
                </Description>
                <Select
                  className="fieldMargin"
                  label=""
                  name="runbook"
                  baseFontSize={13}
                  onChange={(selectedValue) => setRunbook(selectedValue)}
                >

                  <OptionGroup label="CONSUMPTION">
                    <Option value="Support Health Assessments">Support Health Assessments</Option>
                    <Option value="Backup optimisations runbook">Backup optimisations runbook</Option>
                    <Option value="Support Architecture Reviews">Support Architecture Reviews</Option>
                  </OptionGroup>

                  <OptionGroup label="ONBOARD">
                    <Option value="Technical Readiness Review">Technical Readiness Review</Option>
                    <Option value="Technical Success Plan">Technical Success Plan</Option>
                    <Option value="Support post go-live lookback">Support post go-live lookback</Option>
                    <Option value="Pre-prod Design Validation">Pre-prod Design Validation</Option>
                    <Option value="Support go-live">Support go-live</Option>
                  </OptionGroup>
                </Select>
                </>

              )}

              { (runbooksHidden || runbook === "") ? null : 
                <Banner variant="info" className="bannerStyle fieldMargin">
                <strong>{runbook}</strong>
                <br /><br />
                {runbookDescriptions[runbook]}   
              </Banner> 
              }

              <Combobox
                label="CC"
                placeholder="Search"
                onFilter={(event) => searchEmail(event)}
                onChange={(e) => handleExtraEmailField(e)}
                multiselect={true}
              >
                {emailList.map((email: any, index) => {
                  return <ComboboxOption key={index} value={email} />;
                })}
              </Combobox>

              
              <br />

              <hr />
              {isRSCUrgentRequestBannerHidden ? null : (
                <Banner className="bannerStyle fieldMargin">
                To see the RSC's live availabilities, fill-out the 3 fields bellow.<br />For VIP / Urgent requests, use the emergency checkbox /or/ reach out to{" "}
                <a href="mailto:rsc-escalation@mongodb.com">rsc-escalation@mongodb.com</a> with your RD.
                </Banner>
              )}

              
              <Row xs={12} md={12} lg={12}>
                {dateOptionalHidden ? null : (
                  <Checkbox
                    className="xmy-checkbox-x"
                    onChange={() => { setOptionalDateChecked(!isOptionalDateChecked) }}
                    label="I do not yet know the date and time of the meeting"
                    checked={isOptionalDateChecked}
                    bold={false}
                  />
                )}
              </Row>
              
              <br />

              <div className="combobox-container">
              <Select
                
                className="fieldMargin"
                label="Timezone *"
                name="timezone"
                baseFontSize={13}
                value={timezoneValue}
                onChange={(selectedValue) => changeTimezone(selectedValue)}
              >
                {Object.keys(timezones).map((timezone, index, value) => (
                  <Option key={index} value={timezone}>
                    {timezones[timezone]}
                  </Option>
                ))}
              </Select>

              <Select
                className="fieldMargin"
                label="Language * "
                name="languageReq"
                baseFontSize={13}
                value={languagesValue}
                onChange={(selectedValue) => changeLanguage(selectedValue)}
              >
                <Option value="English">🇺🇸 English</Option>
                <Option value="Spanish">🇪🇸 Spanish</Option>
                <Option value="French">🇫🇷 French</Option>
                <Option value="Italian">🇮🇹 Italian</Option>
                <Option value="Portuguese">🇧🇷 Portuguese</Option>
                <Option value="German">🇩🇪 German</Option>
                <Option value="Hindi">🇮🇳 Hindi</Option>
                <Option value="Mandarin">🇨🇳 Mandarin</Option>
                <Option value="Cantonese">Cantonese</Option>
                <Option value="Arabic">Arabic</Option>
                <Option value="Other">Other</Option>
              </Select>
              </div>
              <Label className="fieldMargin" id="description-label" htmlFor='descriptionTextArea'>
                Duration *
              </Label>
              <div className="button-container">
                {durations.map((duration) =>
                  card === "nwtfw" && duration.value === "00:30" ? null : (
                    <Button
                      key={duration.value}
                      onClick={() => changeMeetingDuration(duration.value)}
                      className={`duration-button ${meetingDuration === duration.value ? 'active' : ''}`}
                    >
                      {duration.label}
                    </Button>
                  )
                )}
              </div>
              <br />

              <Row xs={12} md={12} lg={12}>
                    <Checkbox
                      className="xmy-checkbox"
                      onChange={() => {
                        handleEscalationChange(!isEscalationChecked)
                      }}
                      label="Emergency Request"
                      checked={isEscalationChecked}
                      bold={false}
                    />
                    </Row>

              {/*<Badge variant="yellow">Lead Time: {leadTimeText}</Badge>*/}
              

              <br />


              {
                showCalendar === false || isOptionalDateChecked === true ? (
                  <>



                  <br />
                  
                  <br />

                  </>
                
                ) : 
                
                (
                  <>
                    <br/>
                    <h4>Pick Date and time for the meeting</h4>
                    
                    {isEscalationChecked ? (
                      
                      <Banner variant="warning" className="bannerStyle fieldMargin">
                        <ul>
                          <li>Your request will be escalated to our Management team for manual review.</li>
                          <li>Unavailable slots often reflect ongoing client meetings. The details you provided will help us prioritize accordingly.</li>
                          <li>While this allows you to request help at any time, Please note that handling may take longer.</li>
                          <li>We recommend escalating only when the meeting cannot be rescheduled to an available time slot.</li>
                        </ul>
                        <TextArea
                          className="fieldMargin"
                          baseFontSize={13}
                          label='Escalation details'
                          description="Reasons that led to requesting an escalation"
                          value={escalationReasonsValue}
                          onChange={(event) => setEscalationReason(event.target.value)}
                        />
                      </Banner> 

                    ) : null}

                    <br />
                    <DayTimePicker key={resetCalendar} // This prop it is used to remount the component of the calendar everytime one of the params is changed
                      timeSlotSizeMinutes={30}
                      dropdown={false}
                      isDone={dateConfirmed}
                      url="https://zendesk-app-dot-zendesk-scheduler-364414.ue.r.appspot.com/availabilities"
                      //url="http://localhost:8080/availabilities"
                      showAvailableDayTime={apiParameter} 
                      onConfirm={confirmDateCalendar}
                      confirmText={"Click to Confirm Date and time"}
                      doneText={"Registered ! Please, submit your ticket"}
                      hoursFormat={ (timezoneValue.includes("America") || timezoneValue.includes("Australia") || timezoneValue.includes("Kolkata")) ? "h:mm a" : "HH:mm"} 
                      bypass={isEscalationChecked}
                    />
                  </>
                  
                ) 
              }

              <br />

              <FormFooter
                onCancel={() => {
                  navigate(`/`);
                }}
                primaryButton={{
                  text: "Submit",
                  onClick: () => create_ticket(),
                }}
              />
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Toast
          variant="warning"
          title="Validation Error"
          body={
            <>
              {ticketValidation.map((error, index) => (
                <React.Fragment key={index}>
                  * <a>{error}</a>
                  {index !== ticketValidation.length - 1 && <br />}
                </React.Fragment>
              ))}
            </>
          }
          open={validationToastOpen}
          close={() => setValidationToastOpen(false)}
        />
        <Toast
          variant="progress"
          title="Creating ticket"
          body="Sending your ticket information to RSC team"
          open={progressToastOpen}
          close={() => setProgressToastOpen(false)}
        />
        <Toast
          variant="success"
          title="Ticket created successfully"
          body={
            <a href={ticketId} target="_blank" rel="noopener noreferrer">
              Click to open ticket
            </a>
          }
          open={successToastOpen}
          close={() => setSuccessToastOpen(false)}
        />
        <Toast
          variant="warning"
          title={ticketWarning}
          body=""
          open={warningToastOpen}
          close={() => setWarningToastOpen(false)}
        />
      </div>
    </Layout>
  );
};
